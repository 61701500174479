body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

input, select, textarea {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid #202020;
  border-radius: 2px;
  background-color: #2b2b2b;
  color: #eee;
}

input:-moz-read-only, select:-moz-disabled, textarea:-moz-read-only {
  color: #999;
}

input:read-only, select:disabled, textarea:read-only {
  color: #999;
}

.EnumItem select {
  width: 100%;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
