.PropertiesBoxContent {
  padding: 5px;
  border: 1px solid #202020;
  border-radius: 4px;
}


.ParameterItem {
  display: grid;
  width: 100%;
  grid-template-columns: 40% 1fr;
  margin: 5px 0px;
}

.PropertiesBoxContent .ParameterNameCell {
  float: left;
}

.PropertiesBoxContent .ParameterValueCell {
  width: 100%;
}

.ParameterValueCell {
  overflow: hidden;
  text-overflow: ellipsis;
  display: grid;
  grid-template-columns: 1fr 20px;
}

.ParameterValueCell > .link-button {
  padding: 0px;
  height: 18px;
}

.ParameterValueCell .link-button > .icon {
  width: 17px;
  height: 17px;
}

.ParameterValueCell a {
  color: #fff;
  text-decoration: none;
}

.ParameterValueCell a:hover {
  text-decoration: underline;
}

.ParameterValueCell > .BoolLabel{
  color: white;
}

.ParameterValueCell > .reference {
  font-weight: bold;
  font-style: italic;
}

.ParameterValueCell > .input-button {
  margin-left: 2pt;
}

.properties-list .option-block {
  display: flex;
}

.option-block > .radio {
  margin: 5px 0px;
}
