.user-menu-grid {
    font-family: 'Source Sans Pro', sans-serif;
    background-color: #111;
    border: 1px solid #404040;
    padding: 5px;
    border-radius: 4px;
    color: white;
    font-weight: 400;
    font-size: 13pt;
    position: absolute;
    right: 5px;
    top: 43px;
    min-width: 200px;
    -webkit-box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.39);
    -moz-box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.39);
    box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.39);
}

.user-menu-grid .menu-item {
  padding: 10px;
  display: block;
  color: #eee;
  text-decoration: none;
  -moz-transition: all .1s ease-in;
  -o-transition: all .1s ease-in;
  -webkit-transition: all .1s ease-in;
  transition: all .1s ease-in;
}

.user-menu-grid .menu-item-name {
  border-bottom: 1px solid #555;
  color: #bbb;
}


.user-menu-grid .menu-item-clickable:hover {
    background-color: #2b2b2b;
    text-decoration: none;
    cursor: pointer;
}

.user-menu-grid .menu-item-clickable:active {
    background-color: #62a6f3;
}
