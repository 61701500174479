.react-alerts {
  position: fixed;
  right: 0;
  bottom: 0;
  margin: 14px;
  z-index: 999999;
}
.react-alerts .alert {
  width: 300px;
  min-height: 50px;
  margin: 10px 0 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 2px;
  background-color: #333;
  font-size: 11px;
  box-shadow: 0 8px 12px 0 rgba(0,0,0,0.3);
  color: #fff;
}
.react-alerts .alert .content {
  flex: 1;
  text-align: center;
}
.react-alerts .alert .icon {
  width: 32px;
  height: 32px;
}
.react-alerts .alert .icon div {
  width: 100%;
  height: 100%;
  background-position: 50%;
  background-repeat: no-repeat;
}
.react-alerts .alert .icon .info-icon {
  background-image: url("../img/info.png");
}
.react-alerts .alert .icon .error-icon {
  background-image: url("../img/error.png");
}
.react-alerts .alert .icon .success-icon {
  background-image: url("../img/success.png");
}
.react-alerts .alert .message {
  flex: 3;
  text-transform: uppercase;
  padding: 8px 10px;
}
.react-alerts .alert .close {
  height: 50px;
  background-color: #444;
  border-radius: 0 2px 2px 0;
  line-height: 50px;
  cursor: pointer;
}
.react-alerts .alert .close .close-light {
  background-image: url("../img/close-light.png");
  width: 100%;
  height: 100%;
  background-position: 50%;
  background-repeat: no-repeat;
}
.react-alerts .alert .close .close-dark {
  background-image: url("../img/close-dark.png");
  width: 100%;
  height: 100%;
  background-position: 50%;
  background-repeat: no-repeat;
}
.react-alerts .alert .close:hover {
  opacity: 0.5;
}
.react-alerts .alert.hidden {
  display: none;
}
.react-alerts .scale-enter {
  transform: scale(0);
}
.react-alerts .scale-enter.scale-enter-active {
  transform: scale(1);
  transition: all 250ms cubic-bezier(0, 0, 0.5, 1.5);
}
.react-alerts .scale-leave {
  transform: scale(1);
}
.react-alerts .scale-leave.scale-leave-active {
  transform: scale(0);
  transition: all 250ms ease-in-out;
}
.react-alerts .fade-enter {
  opacity: 0.1;
}
.react-alerts .fade-enter.fade-enter-active {
  opacity: 1;
  transition: all 250ms ease-out;
}
.react-alerts .fade-leave {
  opacity: 1;
}
.react-alerts .fade-leave.fade-leave-active {
  opacity: 0.1;
  transition: all 250ms ease-in;
}
