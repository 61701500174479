.flow-node {
    width: 210px;
    border: 2px solid #333;
    border-radius: 10px;
    background-color: #050505;
    user-select: none;
}

.selected > .flow-node {
    border: 2px solid #00BFFF;
}

.flow-node-header {
  cursor: all-scroll;

  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom: 1px solid #353535;

  height: 23px;

  font-size: 13px;
  letter-spacing: 1px;

  color:white;
  font-weight: 600;
  text-transform: uppercase;

  display: grid;
  grid-template-columns: 33px 1fr 27px;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  padding: 8px 0px 3px 0px;
}

.flow-title-text {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.flow-title-help {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.flow-title-help.hidden {
  display: none;
}

.react-flow__node.annotation {
  border-radius: 0;
  text-align: left;
  background: white;
  border: none;
  line-height: 1.4;
  width: 225px;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 15%), 0 2px 4px -1px rgb(0 0 0 / 8%);
}

.react-flow__node.annotation .react-flow__handle {
  display: none;
}

.node-running-status-in_queue > .flow-node-header {
  background-color: rgba(70, 70, 70, 0.7);
}

.node-running-status-running > .flow-node-header {
  background-color: rgba(0, 70, 70, 0.7);
}

.node-running-status-failed > .flow-node-header {
  background-color: rgba(80, 0, 0, 0.7);
}

.node-running-status-success > .flow-node-header {
  background-color: rgba(0, 70, 0, 0.7);
}

.node-running-status-static > .flow-node-header {
  background-color: rgba(128, 96, 44, 0.6);
}

.node-status-deprecated > .flow-node-header {
  text-decoration: line-through;
}

.node-status-mandatory_deprecated > .flow-node-header {
  text-decoration: line-through;
}

.flow-node-icon {
  width: 16px;
  height: 16px;
  padding: 0px 7px 0 9px;
}

.flow-node-item {
  position: relative;
  color: #eee;
  font-size: 17px;
  margin: 10px 0 10px 0;
}

.flow-node-item-output {
  text-align: right;
}

.flow-node-item > .file-type-icon {
    padding: 0 5px 0 5px 0;
    vertical-align: middle;
    margin-left: 4px;
    margin-right: 4px;
}

.flow-node-item-input > .file-type-icon {
    margin-left: 8px;
}

.flow-node-item-output > .file-type-icon {
    margin-right: 8px;
}

.flow-node-item-thumbnail {
    cursor: pointer;
    width: calc(100% - 10px - 2);
    font-size:12px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-decoration: none;
    display: flex;
    text-align: center;
    color: #eee;

    border: 1px solid #444;
    border-radius: 6px;
    margin: 5px;

    transition: all .1s ease-in;
}

.flow-node-item-thumbnail > .preview-button {
    width: 100%;
}

.flow-node-item-thumbnail:hover {
    border-color: #3f6da0;
    background-color: #444;
}

.flow-node-item-thumbnail:active {
    border-color: #00BFFF;
}

.react-flow__minimap-mask {
  fill: rgba(128, 128, 128, 0.5);
}

.react-flow__minimap-node {
  fill: #999;
  stroke: none;
}


.react-flow__edge .react-flow__edge-path {
  stroke: #ccc;
  stroke-opacity: 0.75;
}

.react-flow__edge.selected .react-flow__edge-path,
.react-flow__edge:focus .react-flow__edge-path,
.react-flow__edge:focus-visible .react-flow__edge-path {
  stroke: #00BFFF !important;
  stroke-opacity: 0.75;
}

.flow-node-item .react-flow__handle {
    background: #666;
}

.flow-node-item .react-flow__handle-connecting {
  background: #c00;
}

.flow-node-item .react-flow__handle-valid {
  background: #0c0;
}

.flow-node-footer {
  display: grid;
  grid-template-columns: 1fr 30px;
}

.flow-node-footer.hidden {
  display: none;
}

.flow-button-icon {
  padding: 4px;
  border-radius: 2px;
}

.flow-button:hover .flow-button-icon {
  cursor: pointer;
  background-color: #444;
}

.flow-button:active .flow-button-icon {
  background-color: #62a6f3;
}

.flow-button-icon {
  width: 16px;
  height: 16px;
}

@property --a{
  syntax: '<angle>';
  inherits: false;
  initial-value: 90deg;
}

.flow-node.node-running-status-running::before,
.flow-node.node-running-status-running::after {
  --a:20deg;
  content: '';
  z-index: -1;
  position: absolute;
  width: calc(100%);
  height: calc(100%);
  top: 0px;
  left: 0px;
  border-radius: 10px;
  background: conic-gradient(from var(--a), rgba(0, 0, 0, 0), #00BFFF, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
  animation: border 2s linear infinite;
}

.flow-node.node-running-status-running::after {
  filter: blur(10px);
}

.flow-node-primitive-input .ParameterItem {
  display: grid;
  grid-template-columns: 1fr;
}

.flow-node-primitive-input .ParameterNameCell {
  display: none;
}

.flow-node-primitive-input .ParameterValueCell {
  grid-template-columns: 1fr;
}

.ParameterValueCell .parameter-value {
  margin: 0 5pt 0 5pt;
}

@keyframes border {
  0%, 100% {
      --a: 0deg;
  }

  50% {
      --a: 180deg;
  }

  100% {
    --a: 360deg;
  }
}
