.menu-button {
  display: inline-block;
  border-radius: 2px;
  padding: 2px 10px 2px 10px;
  text-align: center;
  cursor: pointer;
  color: #3363b0;
  font-weight: 600;
  font-size: 13pt;
  text-decoration: none;

  -moz-transition: all .1s ease-in;
  -o-transition: all .1s ease-in;
  -webkit-transition: all .1s ease-in;
  transition: all .1s ease-in;
}

.menu-button:hover {
  display: inline-block;
  background-color: #ccc;
  border-radius: 2px;
  padding: 2px 10px 2px 10px;
  text-align: center;
  text-decoration: underline;
}


.menu-button:active {
  background-color: #62a6f3;
}

.pagination {
  padding-left: 0px;
  margin: 3px;
  user-select: none;
  font-size: 13pt;
  font-weight: 400;
}

.pagination ul {
    display: inline-block;
}

.pagination li {
    display: inline-flex;
    border-radius: 2px;
    color: #ddd;
    font-weight: 400;
    font-size: 13pt;
    white-space: nowrap;
    -moz-transition: all .1s ease-in;
    -o-transition: all .1s ease-in;
    -webkit-transition: all .1s ease-in;
    transition: all .1s ease-in;
}

.pagination li:hover {
  background-color: #555;
}

.pagination li.active {
    background-color: #3f6da0;
}

.pagination li.break-me {
    border: 0px;
    cursor: inherit;
}

.pagination li a {
    padding: 2px 10px 2px 10px;
    cursor: pointer;
}

.control-button.demo-button {
    font-weight: 600;
}

.control-button {
  display: inline-flex;
  border-radius: 2px;
  padding: 2px 10px 2px 10px;
  text-align: center;
  cursor: pointer;
  color: #eee;
  font-weight: 300;
  font-size: 12pt;
  text-decoration: none;
  background-color: #2b2b2b;
  border: 1px solid #111;
  margin: 0px 1px 0px 1px;

  -moz-transition: all .1s ease-in;
  -o-transition: all .1s ease-in;
  -webkit-transition: all .1s ease-in;
  transition: all .1s ease-in;
}

.control-button img {
  vertical-align:middle;
}

.control-button:hover {
  background-color: #444;
}

.control-button:active {
  background-color: #62a6f3;
}

.control-button.selected {
  background-color: #3f6da0;
}

.control-button.demo:hover {
  -webkit-animation: none;
  -moz-animation: none;
  -o-animation: none;
  animation: none;
  background-color: #444;
}

.control-button.demo:active {
  background-color: #62a6f3;
}


.control-button-text {
  padding-left: 5px;
  justify-content: center; /* align horizontal */
  align-items: center; /* align vertical */
}

.control-separator {
  margin-right: 20px;
  display: inline;
}

.control-toggle {
  display: inline-flex;
}

.control-toggle > .control-button {
  border-radius: 0px;
  margin: 0px;
}

.control-button.disabled {
  display: none;
}

.control-toggle > .control-button.disabled {
  display: inline-flex;
  background-color: #444;
  cursor: default;
  color: #777;
}

.control-toggle > .first {
  border-radius: 15px 0px 0px 15px;
}

.control-toggle > .last {
  border-radius: 0px 15px 15px 0px;
}

/* The switch - the box around the slider */
.control-checkbox {
  display: inline-flex;
  padding: 2px 0px 2px 10px;
  text-align: center;
  color: #eee;
  font-weight: 300;
  font-size: 12pt;
  text-decoration: none;
  margin: 0px 1px 0px 1px;

  -moz-transition: all .1s ease-in;
  -o-transition: all .1s ease-in;
  -webkit-transition: all .1s ease-in;
  transition: all .1s ease-in;
}

.control-checkbox.hidden {
  display: none;
}

.disabled .control-checkbox-text {
  color: #333;
}

/* mobile */

.control-button-text {
  display: none;
}

@media (min-width: 1100px) {
  .control-button-text {
    display: flex;
  }
}
