.ListPage {
  overflow:auto;
  background-color: #202020;
  width: 100%;
  height: 100%;
  padding: 0px;
  display: flex;
  flex-direction: column;
}

.ListPage > .List {
  flex: 1;
  overflow:auto;
  border: 1px solid darkgrey;
}

.list {
  background-color: #2b2b2b;
  overflow: auto;
  flex: 1 1;
}

.list-item-link {
  text-decoration: none;
}

.list-item {
  border-radius: 4px;
  margin: 3px;
  color: #ddd;
  font-weight: 400;
  font-size: 12pt;
  cursor: pointer;
  padding: 5px;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;

  display: inline-grid;

  -moz-transition: all .1s ease-in;
  -o-transition: all .1s ease-in;
  -webkit-transition: all .1s ease-in;
  transition: all .1s ease-in;
  background-color: #404040;
}

.header-item .list-field, .list-item .list-field {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.list-header {
  margin: 3px;
  color: #ccc;
  font-weight: 400;
  font-size: 13pt;
  padding: 5px;
  text-decoration: none;
  border-bottom: 1px solid #202020;
  display: inline-grid;
}

.list-item:hover {
  background-color: #555;
}

.list-item:active {
  background-color: #3f6da0;
}

.list-item .star-hidden {
  display: none;
}

.list-item .star-visible {
  display: block;
}
