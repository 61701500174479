.editor-view {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.editor-content {
  overflow: hidden;
  height: 100%;
}
