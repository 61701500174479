.user-view-content {
  width: 100%;
  height: 100%;
  border-top: 1px solid #333;
  display: flex;
  overflow: auto;
}

.user-view-block {
  background-color: #2b2b2b;
  color: #eee;
  width: 900px;
  display: table;
  padding: 5px;
  margin:0 auto;
}

.user-preview {
  display: flex;
  align-items: center;
  font-size: 25pt;
  padding: 30px 0px 30px 0px;
}

.user-view-block .user-icon {
    height: 64px;
    width: 64px;
    padding: 0px;
    border-radius: 50%;
    border: 2px solid #505050;
    margin-left: 15px;
}

.user-display-name {
  padding-left: 15px;
}
