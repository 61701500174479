.NodeProperties {
  padding: 3px;
  margin: 0px;
}

.PropertyCol {
  display: inline-grid;
  width: 400px;
  padding-left: 5px;
}

.PropertyRow {
  display: grid;
  width: 100%;
  grid-template-columns: 35% 65%;
  grid-row-gap: 3px;
}

.NodeProperties .PropertyName {
  color: black;
}

.NodeProperties .PropertyValue {

}

.NodeProperties .Input {
  width: 100%;
}

.NodeProperties .Select {
  width: 100%;
}
