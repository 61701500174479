.graph-flow {
  width: calc(100% - 280px - 310px);    /* TODO use dynamic values*/
}

.graph-flow.read-only {
  width: calc(100% - 310px);    /* TODO use dynamic values*/
}

.GraphNode {
  font-family: 'Source Sans Pro', sans-serif;
  color: #323232;
  line-height: 20px;
  font-size: 14px;
  font-weight: 400;
  height: 100%;
  min-height: 0;

  overflow: auto;

  /* display: flex;
  flex-direction: row; */

  /* display: grid;
  grid-template-columns: 280px 1fr 300px; */

  display: flex;
  flex-direction: row;

  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
}

.BackgroundLabels {
  position: fixed;
  z-index: 10000;
  color: white;
  bottom: 20px;
  line-height: normal;
  display: flex;
  align-items: center;
}

.BackgroundLabels.readonly {
  left: 80px;
}

.BackgroundLabels.editable {
  left: 350px;
}

.BackgroundLabels > .Title {
  font-size: 30px;
}

.BackgroundLabels > .Description {
  font-size: 20px;
}

.GraphRoot {
  flex: 1;
  overflow: scroll;
  display: inline-block;
  height: 100%;
  background-color: #333
}

.PropertiesBar {
  background-color: #2b2b2b;
  width: 300px;
  min-width: 300px;
  padding: 5px;
  overflow: auto;
}

.PropertiesHeader {
  color: #fff;
  vertical-align: middle;
  font-weight: 900;
  font-size: 17px;
  padding: 4px;
}

.PropertiesBar a {
  text-decoration: none;
}

.PropertiesHeader a {
  color: #fff;
  text-decoration: underline;
}

.PropertiesHeader a:hover{
  color: #005599;
}

.PropertiesBoxRoot {
  padding-top: 10px;
}

.PropertiesBox {
  color: #fff;
}

.PropertiesBoxHeader {
  color: #fff;
  font-size: 15px;
  font-weight: 700;
  padding: 5px 0px 3px 5px;
}

.PropertiesBoxContent {
  color: #fff;
  background-color: #404040;
  padding: 4px 0px 4px 0px;
}


@keyframes rollout {
  /*DO NOT COMMIT*/
  0% { transform: translateY(-100px); }
  100% { transform: none; }
}

@keyframes flash {
  0% { opacity: 1; }
  100% { opacity: 0; }
}

.sync-wheel {
  animation: flash 1s;
  display: flex;
  align-items: center;
  opacity: 0;
  margin-left: 6pt;
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #00BFFF;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 18px;
    left: 18px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  4.9% {
    top: 18px;
    left: 18px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  5% {
    top: 18px;
    left: 18px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 36px;
    height: 36px;
    opacity: 0;
  }
}
