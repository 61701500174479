.PreviewBoxContent {
  color: white;
  font-family: monospace;
  text-align: left;
  background-color: #000;
  width: 100%;
  height: 100%;
}

.PreviewBoxContent pre {
  margin-top: 0px;
  background-color: #000;
}

.PreviewBoxContent .note {
  font-family: 'Source Sans Pro', sans-serif, bold;
  font-weight: 700;
  text-align: left;

  width: 100%;
  height: 100%;
  border-bottom: 1px solid #444;
  background-color: #333;
}

.PreviewBoxContent .note .preview-download-link {
  margin-left: 5px;
  cursor: pointer;
  color: #337ab7;
  display: inline;
}

.PreviewBoxContent .note .preview-download-link:hover{
    text-decoration: underline;
}


.preview-table {
  border-spacing: 0px;
  margin: 5px;
}

.preview-table-col {
  padding: 0px 10px 0px 10px;
  border-right: 1px solid #333;
}

.preview-table-row-even {
  background-color: #222;
}

.preview-table-row-odd {
  background-color: #111;
}
