.Header {
  display: block;
  vertical-align: middle;
  /*padding: 8px 16px;*/
  background-color: #111;
  font-size: 17pt;
  font-family: monospace;
  text-align: left;
}

.Header .menu-sl-show {
  display: block;
}

.Header .menu-sl-hide {
  display: none;
}

.Header .logo, .Header .menu-button-sl, .Header .user-menu, .user-icon {
  -moz-transition: all .1s ease-in;
  -o-transition: all .1s ease-in;
  -webkit-transition: all .1s ease-in;
  transition: all .1s ease-in;
}

.Header .logo:hover, .Header .menu-button-sl:hover {
    color: white;
    background-color: #333;
}

.Header .logo:active, .Header .menu-button-sl:active {
  background-color: #62a6f3;
}

.Header a:hover {
  text-decoration: underline;
}

.Header .Navigation {
  position: absolute;
  font-weight: 300;
  color: #ccc;
  font-family: 'Source Sans Pro', sans-serif;
}

.Header .NavigationItems {
  text-align:left;
  background-color:inherit;
  color:inherit;
}

.Header .NavigationItems .Item {
    display:inline-block;
    padding: 0px 18px 0px 18px;
    box-sizing: border-box;
    text-align:left;
    background-color:inherit;
    color:inherit;
    border:none;
    outline:none;
    white-space:normal;
    text-decoration: none;

    -moz-transition: all .1s ease-in;
    -o-transition: all .1s ease-in;
    -webkit-transition: all .1s ease-in;
    transition: all .1s ease-in;
}

.UserButton {
  position: fixed;
  top: 8px;
  right: 10px;
  font-family: 'Source Sans Pro', sans-serif;
  color: white;
  display: flex;
}

.UserButton .button {
  display: inline-block;
  padding: 0px 15px 0px 15px;;
  color: white;
  cursor: pointer;
  text-decoration: none;
}

.UserButton .action {
  display: none;
}

.UserButton .action:hover {
  color: #337ab7;
  text-decoration: underline;
}

/* Mobile dependent */

.Header .logo {
  top: 8px;
  left: 0%;
  margin-left: 80px;
  position: fixed;  /*fixing bug with disapearring header*/
  padding: 3px 15px 3px 15px;
}

.Header > .logo > .icon {
  vertical-align: middle;
  height: 60px;
}

.cog {
  height: 20px;
  margin-left: 15px;
}

.cog > g > .cogPath {
  fill: white;
}

.cog:hover > g > .cogPath {
  fill: #337ab7;
}

.Header .menu-button-sl {
  padding: 5px;
  position: absolute;
  top: 5px;
  left: 10px;
  cursor: pointer;
  display: flex;
}

.Header .menu-button-sl img {
  display:block;
  width: 60px;
  height: 60px;
}

.Header .NavigationItems {
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: 5px;
  background-color: #111;
  font-size: 25pt;
  position: fixed;  /*fixing bug with disapearring header*/
}

.Header .separator {
  display: inline-block;
  border-bottom: 1px #333 solid;
  height: 0px;
}

.Header .Navigation {
  top: 80px;
  left: 0px;
  width: 100%;
  height: calc(100% - 80px);
  z-index: 22000;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
}

.Header .NavigationItems .Item:hover:not(.active) {
    color: white;
    background-color: #333;
}

.Header .NavigationItems .Item {
  padding: 8px;
  margin: 5px;
}

.Header .NavigationItems .active {
    color: #2196F3;
    border-left: 3px solid #2196F3;
}

.Header .NavigationItems .active:hover {
    background-color: #134177;
}

.UserButton {
  top: 8px;
  font-size: 20pt;
  display: block;
}

.UserButton .user-icon {
  height: 64px;
  width: 64px;
  padding: 0px;
  border-radius: 50%;
  border: 2px solid #505050;
}

.user-menu {
  color: white;
  text-decoration: none;
  width: 100%;
  height: 100%;
  display: flex;
}

 .user-icon:hover {
  border-color: #2196F3;
}

@media (min-width: 1100px) {

  .Header .logo {
    top: 4px;
    left: 14px;
    margin-left: 0;
    padding: 0 20px 0 20px;
  }

  .Header > .logo > .icon {
    height: 34px;
  }

  .Header .NavigationItems {
    display: block;
    padding: 0;
    font-size: 17pt;
    width: auto;
  }

  .Header .separator {
    display: inline-block;
    border-left: 1px #333 solid;
    width: 0px;
    height: auto;
  }

  .Header .Navigation {
    top: 6px;
    left: 180px;
    width: auto;
    height: auto;
    padding-left: 10px;
    padding-right: 10px;
    background-color: inherit;
  }

  .Header .NavigationItems .Item:hover:not(.active) {
    border-bottom: 1px solid white;
  }

  .Header .NavigationItems .active {
    border-left: 0;
    border-bottom: 3px solid #2196F3;
  }

  .Header .NavigationItems .Item {
    padding: 0px 18px 0px 18px;
    margin: 0px;
  }

  .UserButton {
    top: 8px;
    font-size: 17pt;
  }

  .UserButton .user-icon {
    height: 24px;
    width: 24px;
  }

  .UserButton .action {
    display: inline-block;
    cursor: pointer;
    padding-left: 10px;
    -moz-transition: all .1s ease-in;
    -o-transition: all .1s ease-in;
    -webkit-transition: all .1s ease-in;
    transition: all .1s ease-in;
  }

}
