.node-list-view {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.node-list-item {
  grid-template-columns: 200px 250px 1fr 200px 200px 200px 200px;
}

.node-list-item .Id {
  min-width: 300px;
}

.node-list-view .Bar {
  background-color: #404040;
}

.node-list-item .node-type {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.status-badge {
    border: 1px solid #808080;
    border-radius: 5px;
    background-color: #202020;
    text-align: center;
    margin-right: 20px;
}

.status-badge.CREATED {
  color: #ffffff;
}

.status-badge.READY {
  color: #00ff20;
}

.status-badge.RUNNING {
  color: #00d8ff;
}

.status-badge.CANCELED {
  color: #ff00ff;
}

.status-badge.SUCCESS {
  color: #00ff00;
}

.status-badge.FAILED {
  color: red;
}

.status-badge.DEPRECATED {
  color: red;
}

.status-badge.MANDATORY_DEPRECATED {
  color: darkred;
}

.node-list-view > .ControlButtons {
  left: 260px;
  top: 6px;
  font-family: 'Source Sans Pro', sans-serif;
}


@media (min-width: 1100px) {
  .node-list-item {
    grid-template-columns: 250px 250px 250px 200px 200px 200px 200px;
    padding: 10px 10px 10px 10px;
  }
}
