.EditNodeMain {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  font-family: 'Source Sans Pro', sans-serif;
}

.EditNodeHeader {
  background-color: #3b3b3b;
  width: 100%;
  padding: 3px;
  border-bottom: 1px solid black;
}

.EditNodeColumn {
  display: flex;
  flex-direction: column;
  height: 100%
}

.EditNodeComponents {
  flex: 1;
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow: auto;
}

.EditNodePropTitle {
  font-weight: 600;
  font-size: 16pt;
  text-align: center;
  background-color: #2b2b2b;
  color: #fff;
}

.EditNodeList {
  flex: 1;
  overflow: auto;
}

.EditNodeColumn {
  background-color: #5b5b5b;
}

.EditNodeParameters {
  flex: 1;
}

.EditNodeInputs {
  width:250px;
}

.EditNodeOutputs {
  width:250px;
}

.EditNodeList .Add {
  font-family: monospace;
  font-size: 35px;
  text-align: center;
  margin: 9px;
  color: #fff;
  background-color: #777;
  border-radius: 5px;
  border: 1px solid #888;
}

.EditNodeList .Add.hover {
  color: #2196F3;
  cursor: pointer;
  background-color: #aaa;
}
