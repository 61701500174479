.SearchBar {
  border: 1px solid #202020;
  border-radius: 2px;
  display: inline-flex;
  grid-template-columns: auto auto;
  background-color: #2b2b2b;
  min-width: 500px;
}

.SearchBar > .Bar {
  border: 0px;
  font-size: 13pt;
  padding-left: 30px;
  background: url("../../../public/icons/search.svg") no-repeat scroll 2px 2px;
  background-size: 18px;
  width: 100%;
  color: #fff;
}
