.hub-panel {
  height: 100%;
  display: flex;
  flex-direction: row;
  background-color: #2b2b2b;
  color: #fff;
  vertical-align: middle;
}

.hub-box {
  color: #fff;
}

.hub-box-header {
  color: #fff;
  font-size: 15px;
  font-weight: 700;
  padding: 5px 0px 3px 5px;
}

.hub-box-header:hover {
  text-decoration: underline;
  cursor: pointer;
}

.hub-box-content {
  color: #fff;
  background-color: #404040;
  border: 1px solid #202020;
  border-radius: 4px;
}

.hub-panel-tab {
    writing-mode: tb-rl;
}

.hub-panel-tab .control-button {
    padding: 10px 4px 10px 2px;
    border-top: 0px;
    border-right: 1px #111 solid;
}


.hub-panel-tab .control-button .hub-tab-title {
    transform: rotate(180deg);
    padding-bottom: 5px;
}

.hub-list-content {
  width: 250px;
  height: 100%;
  display: inline-block;
  flex-direction: column;
  background-color: #404040;
}

.hub-list-entry {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.hub-list-header > .search {
  margin: 3px 3px 3px 6px;
}

.hub-list-header .SearchBar {
  min-width: 230px;
}

.hub-entry-list {
  flex: 1;
  overflow:auto;
  background-color: #404040;
  padding: 2px;
}

.hub-entry-list .closed {
  display: none;
}

.NodeItemDnD {
  cursor: grab;
  margin: 3px 0 3px 0;
}

.hub-arrow {
  border: solid white;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  margin: 0 5px 0 0;
}

.hub-arrow.arrow-right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.hub-arrow.arrow-left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.hub-arrow.arrow-up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.hub-arrow.arrow-down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.hub-entry-group > .hub-entry-list {
  margin-left: 20px;
}

.hub-entry-group-title {
  cursor: pointer;
  display: flex;
  padding-left: 6px;
}

.hub-entry-group-title:hover {
  background-color: #584;
}

.hub-entry-group-title-text {
  display: inline-block;
  font-weight: 700;
}

.hub-entry-group-title-text:hover {
  text-decoration: underline;
}

.hub-item {
  font-weight: 600;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  align-items: center;
  margin: 3px 0 3px 0;
}

.dndnode > .hub-item {
    cursor: grab;
}

.hub-item-node {
  padding: 5px;
  background-color: #333;

  display: grid;
  grid-template-columns: 27px 1fr 16px;
}

.hub-item-node-text {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.hub-item-help {
  display: block;
}

.hub-item-help {
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.hub-item-help.hidden {
  display: none;
}

.dndnode > .hub-item-node:hover {
  background-color: #458;
}

.hub-item-in-or-out {
    background-color: #333;
    padding: 4px;
    padding-left: 17px;
    font-style: italic;
}

.hub-item-in-or-out:hover {
    background-color: #458;
    cursor: pointer;
}

.hub-item-in-or-out:active {
    background-color: #62a6f3;
}

.hub-item-icon {
  width: 16px;
  height: 16px;
  margin: 0 5px 0 5px;
}
