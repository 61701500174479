.NodeItem {
  border: 1px solid transparent;
  border-radius: 4px;
  -webkit-box-shadow: 6px 6px 28px -2px rgba(0,0,0,0.4);
  -moz-box-shadow: 6px 6px 28px -2px rgba(0,0,0,0.4);
  box-shadow: 6px 6px 28px -2px rgba(0,0,0,0.4);
  background-color: rgba(0,0,0,.7);
  margin: 3px;
  font-family: 'Source Sans Pro', sans-serif;
  line-height: 20px;
  font-size: 14px;
  font-weight: 400;
}

.NodeItem > .node-header {
  cursor: grab;
}

.NodeDescription {
  color: white;
  padding: 5px;
  display: inline-grid;
  grid-template-columns: 1fr 40px;
}

.NodeItem .star-hidden {
  display: none;
}

.NodeItem .star-visible {
  display: block;
}

.operation-icon {
  width: 16px;
  height: 16px;
}

.operation-title-text {
  margin-left: 5px;
}
